body {
  overflow-y: auto;
}

.landing-grid {
  background-image: url("./img/fond.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: auto;
}

@media screen and (min-width: 1024px)
{
  .header-color {
    background-image: linear-gradient(to right, #db8a25, #c47c22, #ad6e1e, #97601b, #825318, #74461a, #65391a, #552e19, #44221a, #301817, #1d0f11, #000000);
  }

  .home {
    text-decoration: none;
    color: white;
  }

  .home:hover {
    text-decoration: underline;
    color: white;
  }

  .menu {
    font-size: 20px!important;
  }

  .menu:hover {
    text-decoration: underline;
  }

  .dlCV {
    padding-top: 1em;
  }

  .dlCV a {
    font-weight: bold;
    color: rgba(255, 255, 255, 0.8);
    font-size: 20px;
  }

  .dlCV a:hover {
    color: rgba(185, 185, 185, 0.801);
  }

  .avatar-image {
    height: 250px;
    padding-top: 5em;
  }

  .banner-text {
    background-color: black;
    opacity: .8;
    width: 75%;
    margin: auto;
    border-radius: 10px;
  }

  .banner-text h1 {
    font-size: 400%;
    font-weight: bold;
    color: white;
  }

  .banner-text hr {
    border-top: 5px dotted white;
    width: 50%;
    margin: auto;
  }

  .banner-text p {
    color: white;
    font-size: 180%;
    padding: 1em;
  }

  .social-links {
    width: 50%;
    margin: auto;
  }

  .social-links a {
    color: white;
    font-size: 5em;
    padding: 8px;
  }

  .social-links a:hover {
    color: grey;
  }

  /* Projects */

  .projects-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .cardProject:hover {
    background-color: rgb(235, 235, 235);
  }

  .projects-grid button {
    text-decoration: none;
    padding-bottom: 3em;
    padding-left: 1em;
    padding-right: 1em;
    border: none;
    outline: none;
    background-color: white;
  }

  .projectsTitle {
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-align: center;
  }

  .imgModal {
    width: 330px;
    height: 157px;
    margin: auto;
  }

  /* Contact page*/

  .contact-body {
    margin: auto;
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding-top: 2em;
    overflow: auto;
  }

  .contact-grid {
    text-align: center;
    justify-content: center;
    width: 80%;
    height: 550px;
    background: white;
    color: black;
    margin-bottom: 20px;
  }

  .contact-grid h2 {
    font-family: 'Anton', sans-serif;
  }

  .contact-grid p {
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
  }

  .contact-grid hr {
    border-top: 5px dotted black;
    width: 50%;
    margin: auto;
  }

  .contact-list i {
    font-size: 66px;
    padding-right: 5rem;
  }

  .contact-list a {
    color: rgba(0,0,0, 0.87);
  }

  .noWrap {
    display: flex;
    flex-wrap: nowrap;
    margin: auto;
  }

  .mail {
    margin: auto;
  }

  /* Resume page */

  .resume-right-col {
    background: #27221F;
    color: white;
    padding: 2em;
  }

  #myWebSite {
    text-decoration: underline;
    color: black!important;
  }
}

@media screen and (max-width: 1023px)
{
  .menu {
    font-size: 20px!important;
  }

  .menu:hover {
    text-decoration: underline;
  }

  .dlCV {
    padding-top: 1em;
  }

  .dlCV a {
    font-weight: bold;
    color: rgba(255, 255, 255, 0.8);
    font-size: 20px;
  }

  .dlCV a:hover {
    color: rgba(185, 185, 185, 0.801);
  }

  .avatar-image {
    height: 250px;
    padding-top: 5em;
  }

  .banner-text {
    background-color: black;
    opacity: .8;
    width: 75%;
    margin: auto;
    border-radius: 10px;
  }

  .banner-text h1 {
    font-size: 200%;
    font-weight: bold;
    color: white;
    word-wrap: break-word;
  }

  .banner-text hr {
    border-top: 5px dotted white;
    width: 50%;
    margin: auto;
  }

  .banner-text p {
    color: white;
    font-size: 130%;
    padding: 1em;
    word-wrap: break-word;
  }

  .social-links a {
    color: white;
    font-size: 4em;
    padding: 8px;
  }

  .social-links a:hover {
    color: grey;
  }

  .material-icons {
    color: black;
    position: relative!important;
  }

  .mdl-layout__drawer-button {
    position: relative!important;
  }

  /* Projects */

  .projects-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .cardProject:hover {
    background-color: rgb(235, 235, 235);
  }

  .projects-grid button {
    text-decoration: none;
    padding-bottom: 3em;
    padding-left: 1em;
    padding-right: 1em;
    border: none;
    outline: none;
    background-color: white;
  }

  .projectsTitle {
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-align: center;
  }

  .imgModal {
    width: 330px;
    height: 157px;
    margin: auto;
  }

  .mdl-tabs__tab-bar a {
    font-size: 10px;
  }

  /* Contact page*/

  .contact-body {
    width: 100%;
    height: 100%;
    padding-top: 2em;
    overflow: auto;
  }

  .contact-grid {
    justify-content: center;
    background: white;
    color: black;
  }

  .contact-grid h2 {
    font-family: 'Anton', sans-serif;
  }

  .contact-grid p {
    font-family: 'Oxygen', sans-serif;
    font-weight: bold;
  }

  .contact-grid hr {
    border-top: 5px dotted black;
    width: 50%;
    margin: auto;
  }

  .contact-list i {
    font-size: 66px;
    padding-right: 5rem;
  }

  .contact-list a {
    color: rgba(0,0,0, 0.87);
  }

  /* Resume page */

  .resume-right-col {
    background: #27221F;
    color: white;
    padding: 2em;
  }

  #myWebSite {
    text-decoration: underline;
    color: black!important;
  }
}